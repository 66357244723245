<template>

<app-page :loading="is.loading">

	<app-page-head :title="'Clone ' + noun" :back="$route.meta.back">

		<app-page-head-action :loading="is.cloning" :disabled="!model.clone" icon="tick" tooltip="Confirm clone" v-on:click.native="onConfirmClick" />

	</app-page-head>

	<app-page-content :is-padded="true" :is-grown="true" class="cloner">

		<p>Select a guide to clone from and then search for the {{ noun }} you want to clone. After successfully cloning the {{ noun }} you will be redirected to the newly created copy.</p>

		<app-input-select :disabled="is.cloning" :asString="true" placeholder="Select guide" v-model="model.guide" :options="guideOptions" />
		<app-input-suggest :disabled="is.cloning" :placeholder="'Search for ' + noun" v-model="model.clone" api="cloner/suggest" :params="{type: noun, guide: this.model.guide}" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true,
				cloning: false
			},
			model: {
				guide: false,
				clone: false
			},
			guides: []
		}

	},

	computed: {

		guide: function() {

			return this.model.guide

		},

		guideOptions: function() {

			var options = {}

			this.$_.each(this.guides, function(guide) {

				options[guide.key] = guide.name

			})

			return options

		},

		noun: function() {

			return this.$route.meta.noun

		}

	},

	watch: {

		guide: function() {

			this.model.clone = false

		}

	},

	created: function() {

		this.model.guide = this.$store.getters['guide/key']

		this.$api.get('clone').then(function(json) {

			this.guides = json.guides

			this.is.loading = false

		}.bind(this))

	},

	methods: {

		onConfirmClick: function() {

			this.is.cloning = true

			this.$api.post('clone', {
				type: this.noun,
				id: this.model.clone
			}).then(function(json) {

				this.$store.dispatch('load').then(function() {

					var params = {}

					params[this.noun] = json.id

					this.$router.push({
						name: this.noun.charAt(0).toUpperCase() + this.noun.substring(1).toLowerCase(),
						params: params
					})

				}.bind(this))

			}.bind(this), function() {

				this.is.cloning = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.cloner >>> p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

</style>
